<template>
  <div>
    <el-row
      v-for="(item, index) in zdFormattedValue"
      :key="item.key"
      class="row"
    >
      <el-col :span="2" :offset="1" class="column_title">
        <label for="" :class="getLabelShowClass(index)">{{
          labelText(zdItem.label)
        }}</label>
      </el-col>
      <el-col :span="3">
        <el-input
          :disabled="zdItem.disabled"
          :placeholder="$t('view.placeholder')"
          v-model="zdItem.value.fields"
        ></el-input>
      </el-col>
      <el-col :span="5">
        <el-input
          :disabled="zdItem.disabled"
          :placeholder="$t('view.placeholder')"
          v-model="zdItem.value.specification"
        ></el-input>
      </el-col>
      <el-col :span="2" :offset="2" class="column_title">
        <label for="" :class="getLabelShowClass(index)">{{
          labelText(zdItem.label)
        }}</label>
      </el-col>
      <el-col :span="3">
        <el-input
          :disabled="zdItem.disabled"
          :placeholder="$t('view.placeholder')"
          v-model="zdItem.value.fields"
        ></el-input>
      </el-col>
      <el-col :span="4">
        <el-input
          :disabled="zdItem.disabled"
          :placeholder="$t('view.placeholder')"
          v-model="zdItem.value.test_method"
        ></el-input>
      </el-col>
      <el-col :span="2">
        <el-button
          type="primary"
          icon="el-icon-circle-plus"
          circle
          :disabled="zdItem.disabled"
          @click="addClick(item)"
        ></el-button>
        <el-button
          type="danger"
          :disabled="zdItem.disabled"
          icon="el-icon-delete"
          circle
          @click="removeClick(index)"
        ></el-button>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  name: "ZDTwoColumnCustomInput",
  props: {
    zdId: {
      type: String,
      required: true,
    }
  },
  computed: {
    ...mapGetters("zdInput", {
      zdGetComponentState: "getComponentState",
    }),
    // 获取当前组件的状态对象
    zdComponentState() {
      return this.zdGetComponentState(this.zdId);
    },
    // 从状态对象中获取各个字段
    zdItem() {
      return this.zdComponentState;
    },
    labelText() {
      return (label) => {
        let key = "content." + label;
        key = key.toLowerCase().replaceAll(" ", "_");
        return this.$t(key);
      };
    },
    zdFormattedValue: {
      get() {
        return this.zdItem.value || ""; // 获取输入框的值
      },
      set(value) {
        this.zdUpdateComponent({
          id: this.zdId,
          field: "value",
          value: value,
        }); // 更新输入框的值
      },
    },
    getLabelShowClass() {
      return function (index) {
        return index == 0 ? "" : "white-lable";
      };
    },
  },
  created() {
    // console.log("created:", this.zdItem);
  },
  methods: {
    // 映射 Vuex 的动作
    ...mapActions("zdInput", ["updateComponent"]),
    zdUpdateComponent(payload) {
      this.updateComponent(payload); // 更新组件状态
    },
    defaultValueHandle(value) {
      this.zdUpdateComponent({
        id: this.zdId,
        field: "value",
        value: value,
      }); // 更新输入框的值
    },
    addClick() {
      this.zdFormattedValue.push({});
    },
    removeClick(index) {
      if (this.zdFormattedValue.length > 1) {
        this.zdFormattedValue.splice(index, 1);
      }
    },
  },
};
</script>

<style scoped>
.row {
  margin-bottom: 10px;
}
.white-lable {
  color: white;
}
.column_title {
  vertical-align: middle;
  float: left;
  font-size: 14px;
  color: #606266;
  line-height: 36px;
}
</style>