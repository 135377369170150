<template>
  <div style="position: relative">
    <el-form-item :label="labelText(zdItem.label)">
      <el-input
        type="textarea"
        :disabled="zdItem.disabled"
        v-model="zdFormattedValue"
        :placeholder="$t('view.placeholder')"
        clearable
        :autosize="{ minRows: 2, maxRows: 8 }"
        :style="textareaDefaultStyle"
        @change="handleChange()"
      ></el-input>
      <template v-if="zdItem.tips_value.length > 0">
        <el-popover
          placement="top-start"
          :content="zdItem.tips_value"
          trigger="hover"
          style="position: absolute; right: 10px; top: 10px"
        >
          <el-button
            slot="reference"
            icon="el-icon-edit"
            size="small"
            type="primary"
            :disabled="zdItem.disabled"
            @click="defaultValueHandle(zdItem.tips_value)"
            >Default</el-button
          >
        </el-popover>
      </template>
    </el-form-item>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  name: "ZDTextarea",
  props: {
    zdId: {
      type: String,
      required: true,
    }
  },
  computed: {
    // 从 Vuex 获取当前组件的整个状态
    ...mapGetters("zdInput", {
      zdGetComponentState: "getComponentState",
    }),
    // 获取当前组件的状态对象
    zdComponentState() {
      return this.zdGetComponentState(this.zdId);
    },
    // 从状态对象中获取各个字段
    zdItem() {
      return this.zdComponentState;
    },
    labelText() {
      return (label) => {
        let key = "content." + label;
        key = key.toLowerCase().replaceAll(" ", "_");
        return this.$t(key);
      };
    },
    zdFormattedValue: {
      get() {
        return this.zdItem.value || ""; // 获取输入框的值
      },
      set(value) {
        this.zdUpdateComponent({
          id: this.zdId,
          field: "value",
          value: value,
        }); // 更新输入框的值
      },
    },
    textareaDefaultStyle() {
      return this.zdItem.tips_value
        ? "width: 100%; box-sizing: border-box; padding-right: 120px;"
        : "";
    },
  },
  created() {
    // console.log("created:", this.zdItem);
  },
  methods: {
    // 映射 Vuex 的动作
    ...mapActions("zdInput", ["updateComponent"]),
    zdUpdateComponent(payload) {
      this.updateComponent(payload); // 更新组件状态
    },
    setupValue(value) {
      this.zdUpdateComponent({
        id: this.zdId,
        field: "value",
        value: value,
      });
    },
    defaultValueHandle(value) {
      this.setupValue(value);
    },
    handleChange() {
      
    },
  },
};
</script>
