// type:input、checkbox、tag、select、textarea、custom_input、
import { CSFMU_B } from "./spec/CSFMU_B";
import { CSFMU_S } from "./spec/CSFMU_S";
import { EHXTO } from "./spec/EHXTO";
import { FANDV } from "./spec/FANDV";
import { FMU_D } from "./spec/FMU_D";
import { HEXT } from "./spec/HEXT";
import { Liquid_EXT_B } from "./spec/Liquid_EXT_B";
import { Liquid_EXT_S } from "./spec/Liquid_EXT_S";
import { OEXT } from "./spec/OEXT";
import { PCG_CSFMU_B } from "./spec/PCG_CSFMU_B";
import { PCG_CSFMU_S } from "./spec/PCG_CSFMU_S";
import { PCG } from "./spec/PCG";
import { REXT } from "./spec/REXT";
import { SDNP } from "./spec/SDNP";
import { SDNP_L } from "./spec/SDNP_L";
import { SHXTO } from "./spec/SHXTO";

import { TY_EHXTO } from "./spec/TY_EHXTO"
import { TY_L } from "./spec/TY_L"
import { TY_P } from "./spec/TY_P"
import { TY_SHXTO } from "./spec/TY_SHXTO"

import { EN_Coa } from "./spec/EN_Coa"
import { CN_Coa } from "./spec/CN_Coa"

import { bindDataToTemplate } from './dataMerge'

export const typeData = [
    {
        type: "CSFMU-B",
        data: CSFMU_B
    },
    {
        type: "CSFMU-S",
        data: CSFMU_S
    },
    {
        type: "EHXTO",
        data: EHXTO
    },
    {
        type: "F&V",
        data: FANDV
    },
    {
        type: "FMU-D",
        data: FMU_D
    },
    {
        type: "HEXT",
        data: HEXT
    },
    {
        type: "Liquid EXT-B",
        data: Liquid_EXT_B
    },
    {
        type: "Liquid EXT-S",
        data: Liquid_EXT_S
    },
    {
        type: "OEXT",
        data: OEXT
    },
    {
        type: "PCG-CSFMU-B",
        data: PCG_CSFMU_B
    },
    {
        type: "PCG-CSFMU-S",
        data: PCG_CSFMU_S
    },
    {
        type: "PCG",
        data: PCG
    },
    {
        type: "REXT",
        data: REXT
    },
    {
        type: "SDNP-L",
        data: SDNP_L
    },
    {
        type: "SDNP",
        data: SDNP
    },
    {
        type: "SHXTO",
        data: SHXTO
    },
    // 中文
    {
        type: "TY-EHXTO",
        data: TY_EHXTO
    },
    {
        type: "TY-L",
        data: TY_L
    },
    {
        type: "TY-P",
        data: TY_P
    },
    {
        type: "TY-SHXTO",
        data: TY_SHXTO
    },
    // Coa
    {
        type: "EN-Coa",
        data: EN_Coa
    },
    {
        type: "CN-Coa",
        data: CN_Coa
    },
]


// 数据枚举
export const DataKeys = {
    BASE_INFO: 'base_info',
    GENERAL_SPECIFICATION: 'general_specification',
    ANALYSIS: 'analysis',
    MICROBIOLOGICAL_TESTS: 'microbiological_tests',
    FATTY_ACID: 'fatty_acid',
    OTHER_NOTES: 'other_notes'
};

export const config = {
    getDataByType(productType) {
        console.info('productType:', productType)
        let tmplate = typeData.find(
            (item) => item.type === productType
        ).data;
        console.info('tmplate:', tmplate)
        return tmplate;
    },
    // 使用枚举来获取不同类型的 data
    getTypeDataByKey(type, key) {
        // 确保 key 是枚举中的一个有效值
        if (!Object.values(DataKeys).includes(key)) {
            console.error(`Invalid key: ${key}. Valid keys are:`, Object.values(DataKeys));
            return null;
        }

        // 假设 `typeData` 已经被定义并且是可访问的
        const item = typeData.find(item => item.type === type);

        if (!item) {
            console.error(`No data found for type: ${type}`);
            return null;
        }

        const data = item.data[key];
        return data;
    },
    getFirstLayerAttribute(type) {
        let item = typeData.find(item => item.type === type);
        if (!item) {
            console.error(`No data found for type: ${type}`);
            return null;
        }
        const firstLevelKeys = Object.keys(item.data);
        // console.info('firstLevelKeys:', firstLevelKeys);
        return firstLevelKeys;
    },
    /**
  * 合并模板数据和保存的数据，仅替换 value 字段
  * @param {String} type - 产品类型
  * @param {Object} savedData - 保存的数据，仅包含 value 字段
  * @returns {Object} - 合并后的完整数据
  */
    mergeTemplateWithSavedData(type, savedData, disable = false) {
        const template = this.getDataByType(type);
        console.info('template===>:', template)
        if (!template) {
            console.info('No template found for type:', type)
            return null;
        }

        return bindDataToTemplate(template, savedData, disable);
    },
    /**
  * 将 template JSON 转换为 value JSON
  * @param {Object} templateJson - 原始的 template JSON 对象
  * @returns {Object} - 转换后的 value JSON 对象
  */
    templateToValue(templateJson) {
        // 递归函数来处理每个层级
        function recurse(template) {
            if (Array.isArray(template)) {
                return template.map(item => recurse(item));
            } else if (typeof template === 'object' && template !== null) {
                // 如果对象有 "value" 属性，返回它
                if ('value' in template) {
                    // 特殊处理某些类型，例如 custom_input
                    if (template.type === 'custom_input' || template.type === 'two_column_custom_input') {
                        // 确保 template.value 是数组
                        if (Array.isArray(template.value)) {
                            return template.value.map(field => ({
                                fields: field.fields,
                                value: field.value,
                                specification: field.specification,
                                test_method: field.test_method
                            }));
                        } else {
                            // 如果不是数组，返回一个空数组或处理为默认值
                            console.warn(`Expected an array for ${template.label}, but got ${typeof template.value}. Setting to empty array.`);
                            return [];
                        }
                    }
                    return template.value;
                } else {
                    // 对于没有 "value" 属性的对象，递归处理其属性
                    const result = {};
                    for (const key of Object.keys(template)) {
                        result[key] = recurse(template[key]);
                    }
                    return result;
                }
            } else {
                // 基本类型直接返回
                return template;
            }
        }

        return recurse(templateJson);
    },

    /**
     * 将 value JSON 转换回 template JSON
     * @param {Object} templateJson - 原始的 template JSON 对象（将被修改）
     * @param {Object} valueJson - 需要注入的 value JSON 对象
     * @returns {Object} - 更新后的 template JSON 对象
     */
    valueToTemplate(templateJson, valueJson) {
        // 递归函数来处理每个层级
        function recurse(template, value) {
            if (Array.isArray(template)) {
                return template.map((item, index) => recurse(item, value[index]));
            } else if (typeof template === 'object' && template !== null) {
                // 如果对象有 "value" 属性，设置它
                if ('value' in template) {
                    if (template.type === 'custom_input' 
                        || template.type === 'two_column_custom_input'
                        || template.type === 'three_input'
                        || template.type === 'three_custom_input'
                        || template.type === 'coa_notes_text') {
                        // 特殊处理 custom_input 类型
                        if (Array.isArray(value)) {
                            template.value = value.map(field => ({
                                fields: field.fields || "",
                                value: field.value || "",
                                specification: field.specification || "",
                                test_method: field.test_method || ""
                            }));
                        }else if(value instanceof Object){
                            template.value = value;
                        } else {
                            // 如果 value 不是数组，设置为默认的空数组结构
                            console.warn(`Expected an array for ${template.label}, but got ${typeof value}. Setting to default empty array.`);
                            template.value = [
                                {
                                    fields: "",
                                    value: "",
                                    specification: "",
                                    test_method: ""
                                }
                            ];
                        }
                    } else {
                        template.value = value;
                    }
                } else {
                    // 对于没有 "value" 属性的对象，递归处理其属性
                    for (const key of Object.keys(template)) {
                        if (Object.prototype.hasOwnProperty.call(value, key)) {
                            recurse(template[key], value[key]);
                        }
                    }
                }
                return template;
            } else {
                // 基本类型不处理
                return template;
            }
        }

        // 深拷贝模板以避免修改原始对象
        const updatedTemplate = JSON.parse(JSON.stringify(templateJson));
        recurse(updatedTemplate, valueJson);
        return updatedTemplate;
    }
};