<template>
  <div>
    <el-button
      icon="el-icon-tickets"
      @click="importFromSpec()"
      :loading="importSpecLoading"
      >{{ labelText("Import From Spec") }}</el-button
    >
    <el-button
      type="primary"
      @click="saveHandle()"
      :loading="saveLoading"
      >{{ labelText("Save") }}</el-button
    >
    
    <el-button
      type="warning"
      >{{ labelText("Verify") }}</el-button>

    <el-button
      type="primary"
      :loading="previewPdfLoading"
      @click="previewPDF()"
      >{{ labelText("Preview PDF") }}</el-button
    >
    <el-button @click="goBack()">{{ labelText("Back Home") }}</el-button>
  </div>
</template>
<script>
// import * as StructConvertUtils from "../../spec/config/structConvertUtils";
// import dayjs from "dayjs";
import { mapGetters } from "vuex";
import { apiServer } from "@/network/modules/detail-api";
// import * as TemplateUtils from '../../template/index'

export default {
  props: {
    isPrintSignature: Boolean
  },
  computed: {
    ...mapGetters("zdInput", {
      zdGetComponentState: "getComponentState",
    }),
    optionButtonFlag() {
      return this.pageStatus == "Detail";
    },
    labelText() {
      return (label) => {
        let key = "action." + label;
        key = key.toLowerCase().replaceAll(" ", "_");
        return this.$t(key);
      };
    },
  },
  inject: ["getModulesDataMethod","importSpecDataMethod"],
  data() {
    return {
      language: "EN",
      saveLoading: false,
      verifyLoading: false,
      previewPdfLoading: false,
      importSpecLoading:false,
      pageStatus: "Add",
    };
  },
  methods: {
    importFromSpec(){
      this.importSpecLoading = true;
      this.importSpecDataMethod(()=>{
        this.importSpecLoading = false;
      })
    },
    // 保存按钮点击
    saveHandle() {
      let type = this.$route.query.type;

      this.saveLoading = true;
      this.$emit("save", (res) => {
        // Save 的时候没有获取到已经更新的文本值
        let params = this.getSaveData(res);
        params.lot = res.base_info.lot;
        params.manufacutureData = res.base_info.manufacuture_date;
        params.expirationDate = res.base_info.expiration_date;

        // TODO:外面导入，需要 excel
        params.chinaLot = '';
        params.tyNumber = '';
        params.qty = '';
        params.shippingMethod = "";
        params.shipmentDate = "";
        params.verified = 0;

        console.info('params:',params)
        
        apiServer.getDataByType(type, "saveOrUpdate", params, () => {
          this.saveLoading = false;
            this.$message({
              message: this.$t("confirm.save_success"),
              type: "success",
            });
          this.saveLoading = false;
        });
      });
    },
    // 获取保存数据
    getSaveData(data) {
      let paramsId = "";
      console.info('this.$route.query.isCopy:',this.$route.query.isCopy)
      console.info('this.$route.query.id:',this.$route.query.id)

      const isCopy = this.$route.query.isCopy === 'false' ? false : !!this.$route.query.isCopy;

      if (this.$route.query.id && !isCopy) {
        paramsId = this.$route.query.id;
      }
     
      let saveData = {
        id: paramsId,
        itemCode: data.base_info.item_code,
        productType: data.base_info.product_type,
        productName: data.base_info.product_name,
        productDescription: data.base_info.product_description,
        physicalProperties: data.base_info.physical_properties,
        countryOfOrigin: data.general_specification.country_of_origin,
        botanicalName: data.general_specification.botanical_name.toString(),
        shelflifeNumber: 0,
        verifiedListUser: "",
        verifiedList: 0,
        revNumber: 1,
        verified_time:"",
        rev_number:1,
        jsonValue: JSON.stringify(data),
      };
      return saveData;
    },
    previewPDF(){

    },
    goBack(){
      window.history.back();
    }
  },
  created() {
    
  },
};
</script>