// utils/dataMerge.js
export function bindDataToTemplate(template, data, disable = false) {
  // 如果模板是数组，递归处理每个元素
  if (Array.isArray(template)) {
    return template.map((item, index) => bindDataToTemplate(item, data ? data[index] : undefined, disable));
  }

  // 如果模板是对象，处理每个键
  if (typeof template === 'object' && template !== null) {
    const result = Array.isArray(template) ? [] : {};

    for (const key in template) {
      if (Object.prototype.hasOwnProperty.call(template, key)) {
        const templateValue = template[key];
        const dataValue = data ? data[key] : undefined;

        if (typeof templateValue === 'object' && templateValue !== null) {
          // 检查是否有 `value` 字段
          if ('value' in templateValue) {
            result[key] = {
              ...templateValue, // 保留模板的其他字段
              value: dataValue !== undefined ? dataValue : templateValue.value,
              disabled: disable ? true : templateValue.disabled // 设置 `disabled`
            };
          } else {
            // 递归处理嵌套对象
            result[key] = bindDataToTemplate(templateValue, dataValue, disable);
          }
        } else {
          // 非对象类型直接赋值（通常不会发生，因为模板中的非对象字段很少）
          result[key] = dataValue !== undefined ? dataValue : templateValue;
        }
      }
    }

    return result;
  }

  // 其他类型直接返回数据值或模板值
  return data !== undefined ? data : template;
}

export function extractInitialValues(template) {
  if (Array.isArray(template)) {
    return template.map(item => extractInitialValues(item));
  } else if (typeof template === 'object' && template !== null) {
    if ('value' in template) {
      return template.value;
    }
    const result = {};
    for (const key in template) {
      if (Object.prototype.hasOwnProperty.call(template, key)) { // 修改此行
        result[key] = extractInitialValues(template[key]);
      }
    }
    return result;
  } else {
    return template;
  }
}