<template>
  <div class="new-sds-container" v-loading="loading">
    <el-tabs type="border-card" v-model="tabModel" @tab-click="handleTabClick">
      <el-tab-pane
        v-loading="loading"
        element-loading-text="data loading"
        name="tab1"
        class="tab"
      >
        <span slot="label"><i class="el-icon-date"></i> Detail Manager</span>
        <ZDComponentsVue
          v-if="isComponentsLoad"
          :modules="detailData"
          :tabId="'tab1'"
          :key="detailDataKey"
        ></ZDComponentsVue>
      </el-tab-pane>
      <el-tab-pane
        v-for="(item, index) in auditedList"
        :key="item.id"
        :name="'audited_tab' + (index + 2)"
      >
        <span slot="label"
          ><i class="el-icon-date"></i> Version {{ index + 1 }}
        </span>
        <ZDComponentsVue
          :modules="getAuditedData(item)"
          :tabId="'tab' + (index + 2)"
        ></ZDComponentsVue>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import ZDComponentsVue from "./ZDComponents.vue";
import { apiServer } from "@/network/modules/detail-api";
import * as TemplateData from "@/views/NEW/module/template/index.js";
export default {
  components: {
    ZDComponentsVue,
  },
  computed: {
    isComponentsLoad() {
      return this.detailData && Object.keys(this.detailData).length;
    },
  },
  data() {
    return {
      loading: false, // 加载中
      auditedList: [], // 版本信息
      tabModel: "tab1",
      pageStatus: "Add", // 页面状态：Add、Detail、Edit
      productType: "CSFMU-B", //默认的产品类型，spec 为 CSFMU-B、COA 为 CSFMU-A
      itemCode: "",
      detailData: {}, //详情数据
      notesData: {}, //备注信息
      detailDataKey: 0,
    };
  },
  provide() {
    return {
      productTypeChange: this.productTypeChange,
      getNotesDataMethod: this.getNotesData,
      setNotesDataMethod: this.setCoaNotes,
      importSpecDataMethod: this.loadSpecDataByItemCode,
      setItemCodeDataMethod: this.setItemCode,
    };
  },
  created() {
    let id = this.$route.query.id;
    let isCopy = this.$route.query.isCopy;
    let status = "Add";

    if (isCopy == true) {
      this.loadData();
      this.loadAuditVersionData();
    } else {
      if (id) {
        status = "Edit";
        this.loadData();
        this.loadAuditVersionData();
      } else {
        this.loadTemplateData();
      }
    }

    this.pageStatus = status;

    localStorage.setItem("pageStatus", this.pageStatus);
    localStorage.setItem("currentTabName", this.tabModel);
  },
  methods: {
    setItemCode(itemCode) {
      this.itemCode = itemCode;
    },
    getNotesData() {
      return this.notesData;
    },
    setCoaNotes(notes) {
      this.notesData = Object.assign({}, notes);
    },
    // tab切换
    handleTabClick(tab) {
      console.info(tab.name);
      if (tab.name === "tab1") {
        this.pageStatus = "Edit";
      } else {
        this.pageStatus = "Detail";
      }
      localStorage.setItem("pageStatus", this.pageStatus);
      localStorage.setItem("currentTabName", this.tabModel);
      this.$EventBus.$emit("pageStatusChange", this.pageStatus);
    },
    // 产品类型切换
    productTypeChange(type) {
      this.loadTemplateData(type);
    },
    // 加载详情数据
    loadData() {
      console.info("loadData==");
      this.loading = true;
      let type = this.$route.query.type;
      let id = this.$route.query.id;
      apiServer.getDataByType(type, "getDetail", { id }, (data) => {
        let tmpData = JSON.parse(data.jsonValue);
        if (tmpData.notes) {
          this.notesData = tmpData.notes;
        }
        console.info("json:", tmpData);

        let productType = this.getCoaType(data.productType);
        console.info("==productType:", productType);
        const template = TemplateData.config.getDataByType(productType);
        let templateVal = TemplateData.config.valueToTemplate(
          template,
          tmpData
        );
        console.info("tmp---:", templateVal);
        this.detailData = templateVal;
        // this.$set(this, "detailData", templateVal || {});
        this.loading = false;
      });
    },
    // 加载版本数据
    loadAuditVersionData() {
      this.loading = true;
      let type = this.$route.query.type;
      if (type == "coa") {
        // coa 没有版本数据
        console.info("return coa");
        return;
      }
      let id = this.$route.query.id;
      apiServer.getDataByType(type, "auditedList", { id }, (data) => {
        this.$set(this, "auditedList", data || []);
        this.loading = false;
      });
    },
    loadSpecDataByItemCode(callback) {
      let itemCode = this.itemCode;
      if (itemCode == null || itemCode === "") {
        this.$message.error(this.$t("confirm.item_code_empty"));
        return;
      }
      apiServer.getDataByType(
        "spec",
        "auditedLatestInfo",
        { itemCode },
        (data) => {
          console.info("load spec data:", data);
          if (data) {
            const jsonValue = JSON.parse(data.jsonValue)
            //导入 “print_title”,  “product_name”, “customer_product_name”,  “product_type“, “version_number”
            const baseInfoKeys = [
              "print_title",
              "product_name",
              "customer_product_name",
              "product_type",
              "version_number",
            ];

            baseInfoKeys.forEach((key) => {
              this.$set(this.detailData.base_info[key], 'value', jsonValue.base_info[key] ?? "");
            });
            this.$set(this.detailData.base_info['print_title'], 'value', "test print title");
            this.$set(this.detailData.base_info['product_name'], 'value', "test product name");

            this.detailData = { ...this.detailData };
            this.detailDataKey += 1; // 更新 key 以强制重新渲染
            console.info("detailData===>:", this.detailData);

            this.$message({
              message: this.$t("confirm.import_success"),
              type: 'success'
            });
          } else {
            this.$message.error(this.$t("confirm.item_code_no_audited_record"));
          }
          if (callback) callback();
        }
      );
    },
    // 绑定 module
    getAuditedData(item) {
      let tmpData = JSON.parse(item.jsonValue);

      const mergedData = TemplateData.config.mergeTemplateWithSavedData(
        item.productType,
        tmpData,
        true
      );
      console.info("getAuditedData:", mergedData);
      return mergedData;
    },
    getCoaType(productType) {
      let type = this.$route.query.type;
      if (type == "coa") {
        let lang = localStorage.getItem("language").toUpperCase();
        productType = lang + "-Coa";
      }
      return productType;
    },
    // 加载模板数据
    loadTemplateData(productType = "CSFMU-B") {
      this.productType = productType;

      let templateData = TemplateData.config.getDataByType(productType);
      // 使用 Vue.set 以确保响应式
      this.$set(this, "detailData", templateData || {});
    },
  },
};
</script>

<style scoped>
</style>
