<template>
  <div>
    <div class="tags">
      <el-form-item :label="labelText(zdItem.label)">
        <!-- 遍历 tags 显示标签 -->
        <el-tag
          :key="index"
          v-for="(tag, index) in zdFormattedValue"
          closable
          :disabled="setupDisable()"
          :disable-transitions="false"
          style="margin-right: 10px; margin-top: 10px;float: left;"
          @close="removeTag(tag, 'tags')"
        >
          <!-- 可点击的标签文本 -->
          <span @click="editTag(tags, tag, index)" style="cursor: pointer">{{
            tag
          }}</span>
          <!-- 添加新标签的按钮 -->
          <el-button
            type="primary"
            :disabled="setupDisable()"
            style="padding: 0 2px; margin-left: 5px"
            size="small"
            @click="
              showTagInput('isInputVisible', 'tagInputRef', index, 'tagIndex')
            "
            >+</el-button
          >
          <!-- 向左移动标签的按钮 -->
          <el-button
            v-show="index > 0"
            type="primary"
            :disabled="zdItem.disabled"
            style="padding: 0"
            size="small"
            icon="el-icon-arrow-left"
            @click="moveTagLeft(index)"
          ></el-button>
          <!-- 向右移动标签的按钮 -->
          <el-button
            type="primary"
            :disabled="zdItem.disabled"
            style="padding: 0"
            size="small"
            icon="el-icon-arrow-right"
            @click="moveTagRight(index)"
          ></el-button>
        </el-tag>
        <!-- 添加新标签的输入框 -->
        <el-input
          class="input-new-tag"
          v-if="isInputVisible"
          :disabled="zdItem.disabled"
          v-model="inputValue"
          ref="tagInputRef"
          size="small"
          @keyup.enter.native="
            confirmInput()
          "
          @blur="confirmInput()"
        ></el-input>
        <!-- 显示输入框的按钮 -->
        <el-button
          :disabled="zdItem.disabled"
          style="float: left;margin-top: 10px;"
          v-else
          :class="tagStyle"
          size="small"
          @click="showInput('isInputVisible', 'tagInputRef')"
          >+ {{$t('action.add')}} {{labelText(zdItem.label)}}</el-button
        >
      </el-form-item>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  name: "ZDTag",
  props: {
    zdId: {
      type: String,
      required: true,
    }
  },
  computed: {
    // 从 Vuex 获取当前组件的整个状态
    ...mapGetters("zdInput", {
      zdGetComponentState: "getComponentState",
    }),
    // 获取当前组件的状态对象
    zdComponentState() {
      return this.zdGetComponentState(this.zdId);
    },
    // 从状态对象中获取各个字段
    zdItem() {
      return this.zdComponentState;
    },
    labelText() {
      return (label) => {
        let key = "content." + label;
        key = key.toLowerCase().replaceAll(" ", "_");
        return this.$t(key);
      };
    },
    zdFormattedValue: {
      get() {
        return this.zdItem.value || ""; // 获取输入框的值
      },
      set(value) {
        this.zdUpdateComponent({
          id: this.zdId,
          field: "value",
          value: value,
        }); // 更新输入框的值
      },
    },
    tagStyle() {
      if (this.zdFormattedValue.length > 0) {
        return "button-new-tag";
      }
      return "";
    },
  },
  data() {
    return {
      isInputVisible: false, // 控制输入框可见性的布尔值
      inputValue: "", // 输入框的值
    };
  },
  created() {
    // console.log("created:", this.zdItem);
  },
  methods: {
    // 映射 Vuex 的动作
    ...mapActions("zdInput", ["updateComponent"]),
    zdUpdateComponent(payload) {
      this.updateComponent(payload); // 更新组件状态
    },
    handleChange() {
      console.info("this.zdItem:",this.zdItem)
      this.zdUpdateComponent({
        id: this.zdId,
        field: "value",
        value: this.zdItem.value,
      });
    },
    sendTagDataHandle() {},
    // 显示输入框并聚焦
    showInput(isInputVisible, ref) {
      this[isInputVisible] = true;
      this.$nextTick(() => {
        this.$refs[ref].$refs.input.focus();
      });
    },
    // 在特定索引显示输入框并聚焦
    showTagInput(isInputVisible, ref, index, tagIndex) {
      this[tagIndex] = index + 1;
      this[isInputVisible] = true;
      this.$nextTick(() => {
        this.$refs[ref].$refs.input.focus();
      });
    },
    // 处理输入确认并将其添加到标签列表中
    confirmInput() {
      let inputValue = this.inputValue;
      if (inputValue) {
        // 将输入值添加到指定索引或末尾
        this.zdFormattedValue.push(inputValue);
        this.sendTagDataHandle();
      }
      this.isInputVisible = false;
      this.inputValue = "";
    },
    // 处理点击标签以编辑其值
    editTag(array, tagContent, index) {
      this.$prompt("修改值-->" + tagContent, "温馨提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
      })
        .then(({ value }) => {
          array.splice(index, 1, value);
        })
        .catch(() => {});
    },
    // 向左移动标签
    moveTagLeft(index) {
      let tempTag = this.zdFormattedValue[index - 1];
      this.$set(this.zdFormattedValue, index - 1, this.zdFormattedValue[index]);
      this.$set(this.zdFormattedValue, index, tempTag);
    },
    // 向右移动标签
    moveTagRight(index) {
      let tempTag = this.zdFormattedValue[index + 1];
      this.$set(this.zdFormattedValue, index + 1, this.zdFormattedValue[index]);
      this.$set(this.zdFormattedValue, index, tempTag);
    },
    removeTag(index) {
      this.zdFormattedValue.splice(this.zdFormattedValue.indexOf(index), 1);
    },
    setupDisable(){
      let type = this.$route.query.type;
      const pageStatus = localStorage.getItem("pageStatus")
      
      if(type =='coa' && pageStatus=='Edit'){
        let tmpArr =['product_name','customer_product_name','product_type',"ingredients","country_of_origin"]
        let key = this.zdItem.label.toLowerCase().replaceAll(" ", "_");
        if(tmpArr.includes(key)){
          return true;
        }
      }
      return this.zdItem.disabled;
    }
  },
};
</script>

<style>
.tags {
  display: flex;
  justify: flex-start;
  flex-wrap: wrap;
  align-items: center;
}
.button-new-tag {
  margin-left: 10px;
}
</style>