<template>
  <div>
    <el-form-item :label="labelText(zdItem.label)">
      <div
        v-for="(item, index) in zdFormattedValue"
        :key="item.key"
        class="custom-field"
      >
        <el-row>
          <el-col :span="6">
            <el-input
              v-model="item.fields"
              :disabled="viewDisabled"
              :placeholder="$t('view.placeholder')"
            ></el-input>
          </el-col>

          <el-col :span="14" :offset="1">
            <el-input
              v-model="item.value"
              :disabled="viewDisabled"
              :placeholder="$t('view.placeholder')"
            ></el-input>
          </el-col>
          <!-- 右侧列显示添加和删除按钮 -->
          <el-col :span="3">
            <el-button
              type="primary"
              icon="el-icon-circle-plus"
              circle
              :disabled="viewDisabled"
              @click="addClick(item)"
            ></el-button>
            <el-button
              type="danger"
              :disabled="viewDisabled"
              icon="el-icon-delete"
              circle
              @click="removeClick(index)"
            ></el-button>
          </el-col>
        </el-row>
      </div>
    </el-form-item>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  name: "ZDCustomInput", // 设置组件名称为 ZDInput
  props: {
    zdId: {
      type: String,
      required: true,
    }
  },
  computed: {
    // 从 Vuex 获取当前组件的整个状态
    ...mapGetters("zdInput", {
      zdGetComponentState: "getComponentState",
    }),
    // 获取当前组件的状态对象
    zdComponentState() {
      return this.zdGetComponentState(this.zdId);
    },
    // 从状态对象中获取各个字段
    zdItem() {
      return this.zdComponentState;
    },
    labelText() {
      return (label) => {
        let key = "content." + label;
        key = key.toLowerCase().replaceAll(" ", "_");
        return this.$t(key);
      };
    },
    viewDisabled(){
      let pageStatus = localStorage.getItem("pageStatus")
      if(pageStatus=='Detail'){
        return false;
      }
      return this.zdItem.disabled
    },
    zdFormattedValue: {
      get() {
        if (this.zdItem.value.length == 0) {
          return [
            {
              fields: "",
              value: "",
              specification: "",
              test_method: "",
            },
          ];
        }
        return this.zdItem.value; // 获取输入框的值
      },
      set(value) {
        this.zdUpdateComponent({
          id: this.zdId,
          field: "value",
          value: value,
        }); // 更新输入框的值
      },
    },
  },
  created() {
    // console.log("created:", this.zdItem);
  },
  methods: {
    // 映射 Vuex 的动作
    ...mapActions("zdInput", ["updateComponent"]),
    zdUpdateComponent(payload) {
      this.updateComponent(payload); // 更新组件状态
    },
    defaultValueHandle(value) {
      this.zdUpdateComponent({
        id: this.zdId,
        field: "value",
        value: value,
      }); // 更新输入框的值
    },
    addClick() {
      this.zdFormattedValue.push({});
    },
    removeClick(index) {
      if (this.zdFormattedValue.length > 1) {
        this.zdFormattedValue.splice(index, 1);
      }
    },
  },
};
</script>
