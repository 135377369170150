<template>
  <div>
    <el-row>
      <el-col :span="12">
        <el-form-item :label="labelText(zdItem.label)">
          <el-input
            :placeholder="$t('view.placeholder')"
            :disabled="zdItem.disabled"
            v-model="zdFormattedValue.specification"
          >
            <!-- 如果有提示信息，则显示默认值按钮 -->
            <template v-if="zdItem.tips_value.specification" #suffix>
              <el-popover
                placement="top-start"
                :content="zdItem.tips_value.specification"
                trigger="hover"
                class="text-center"
              >
                <el-button
                  slot="reference"
                  icon="el-icon-edit"
                  size="small"
                  type="primary"
                  :disabled="zdItem.disabled"
                  @click="defaultValueHandle(zdItem.tips_value)"
                >
                  {{ $t("action.default") }}
                </el-button>
              </el-popover>
            </template>
          </el-input>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item :label="labelText(zdItem.label)">
          <el-input
            :placeholder="$t('view.placeholder')"
            :disabled="zdItem.disabled"
            v-model="zdFormattedValue.test_method"
          >
            <!-- 如果有提示信息，则显示默认值按钮 -->
            <template v-if="zdItem.tips_value.test_method" #suffix>
              <el-popover
                placement="top-start"
                :content="zdItem.tips_value.test_method"
                trigger="hover"
                class="text-center"
              >
                <el-button
                  slot="reference"
                  icon="el-icon-edit"
                  size="small"
                  type="primary"
                  :disabled="zdItem.disabled"
                  @click="defaultValueHandle(zdItem.tips_value)"
                >
                  {{ $t("action.default") }}
                </el-button>
              </el-popover>
            </template>
          </el-input>
        </el-form-item>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  name: "ZDTwoColumnInput", // 设置组件名称为 ZDInput
  props: {
    // 组件的唯一 ID，用于在 Vuex 中标识和管理其状态
    zdId: {
      type: String,
      required: true,
    }
  },
  computed: {
    // 从 Vuex 获取当前组件的整个状态
    ...mapGetters("zdInput", {
      zdGetComponentState: "getComponentState",
    }),
    // 获取当前组件的状态对象
    zdComponentState() {
      return this.zdGetComponentState(this.zdId);
    },
    // 从状态对象中获取各个字段
    zdItem() {
      return this.zdComponentState;
    },
    labelText() {
      return (label) => {
        let key = "content." + label;
        key = key.toLowerCase().replaceAll(" ", "_");
        return this.$t(key);
      };
    },
    zdFormattedValue: {
      get() {
        return this.zdItem.value || ""; // 获取输入框的值
      },
      set(value) {
        this.zdUpdateComponent({
          id: this.zdId,
          field: "value",
          value: value,
        }); // 更新输入框的值
      },
    },
  },
  created() {
    // console.log("created:", this.zdItem);
  },
  methods: {
    // 映射 Vuex 的动作
    ...mapActions("zdInput", ["updateComponent"]),
    zdUpdateComponent(payload) {
      this.updateComponent(payload); // 更新组件状态
    },
    defaultValueHandle(value) {
      this.zdUpdateComponent({
        id: this.zdId,
        field: "value",
        value: value,
      }); // 更新输入框的值
    },
  },
};
</script>
